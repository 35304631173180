const transformers = {
    // Default - return as-is
    defaultTransformer: (response) => response,

    cloudinaryImageOptz: (url, width, height) => {
        const urlParts = url.split('upload/');
        return `${urlParts[0]}upload/c_fill,g_auto,f_auto,w_${width},h_${height}/${urlParts[1]}`;
    },

    stripHtml: (val) => {
        return val.replace(/<[^>]*>/g, '');
    }
    // Add more transformers as needed
};

export const getTransformer = (key) => {
    const transformer = transformers[key] || transformers.defaultTransformer;
    return transformer;
};
