import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Stack,
    Img,
    Text,
    Heading,
    useTheme
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {Link} from 'react-router-dom'

const PreviewCard = ({img, href, title, description, metadata, ...props}) => {
    const {src, alt} = img;
    const theme = useTheme();

    return (
        <Box {...props}>
            <a href={href}>
                <Img
                    src={src}
                    alt={alt}
                    height={240}
                    objectFit={'cover'}
                />
                <Stack mt='6' spacing='3'>
                    <Heading size='md'>{title}</Heading>
                    <Box display={'flex'}>
                        {metadata.map((m) => {
                            return (
                                <Text 
                                    as={'span'}
                                    marginRight={4}
                                    fontSize='sm' 
                                    textTransform='uppercase'>
                                        {m}
                                </Text>        
                            )
                        })}
                    </Box>
                    <Text>{description}</Text>
                </Stack>
            </a>
        </Box>
    )

}

PreviewCard.displayName = 'PreviewCard';
PreviewCard.propTypes = {
    href: PropTypes.string.isRequired,
    img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
    }).isRequired,
    title: PropTypes.string.isRequired
};

export default PreviewCard;