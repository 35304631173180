import React from 'react'
import {Box, Heading, Image, Container} from '@salesforce/retail-react-app/app/components/shared/ui'
import { getTransformer } from '../../conscia/conscia-transformers'

const BillboardBanner = ({title, subtitle, img, actions, layout, ...props}) => {
    const {src, alt} = img
    // const {height, width} = props
    let width, height
    if (layout === 'boxed') {
        height = '600px'
        width = '80%'
    } else {
        height = '600px'
        width = '100%'
    }
    console.log('BillboardBanner:', props)
    return (
        <Box marginBottom={'0'} position={{lg: 'relative'}}  width={width} {...props} className='Test' margin={'auto'}>
            <Box position={'relative'} width={{base: 'full', md: 'full', lg: 'full'}} margin={'auto'}>
                <Image
                    fit={'cover'}
                    align={'center'}
                    margin={'auto'}
                    width={'100%'}
                    height={height}
                    src={src}
                    alt={alt}
                />
            </Box>
            <Container
                align={{base: 'center', lg: 'left'}}
                position={{base: 'relative', lg: 'absolute'}}
                top={'0'}
                left={'0'}
                right={'0'}
                spacing={{base: 8, md: 10}}
                paddingTop={{base: 12, md: 10}}
                paddingBottom={{base: 6, md: 10}}
                paddingLeft={{base: 12, md: 10}}
                direction={{base: 'row', lg: 'row'}}
            >
                <Heading
                    as="h1"
                    fontSize={{base: '5xl', md: '5xl', lg: '6xl'}}
                    color={{base: '#000', lg: '#fff'}}
                    textShadow={{base: 'none', lg: '0 0 25px #000'}}
                >
                    {title ? getTransformer('stripHtml')(title): ''}
                </Heading>
                <Heading
                    as="p"
                    fontSize={{base: 'md', md: 'lg', lg: 'lg'}}
                    color={{base: '#000', lg: '#fff'}}
                    textShadow={{base: 'none', lg: '0 0 25px #000'}}
                >
                    {subtitle ? getTransformer('stripHtml')(subtitle) : ''}
                </Heading>
            </Container>
        </Box>
    )
}

export default BillboardBanner
