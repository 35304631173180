import React, { useEffect, useRef } from 'react';
import { Box, Heading, Text } from '@salesforce/retail-react-app/app/components/shared/ui';
import Section from '@salesforce/retail-react-app/app/components/section';

const VideoPlayer = ({ videoUrl, alt, title, width='100%', height='auto' }) => {
    return (
        <Box>
            <Section
                padding={4}
                paddingTop={16}
                title={title}
                subtitle={alt}
            />
            <video
                controls
                width={width}
                height={height}
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Box>
    );
}

export default VideoPlayer;
