import React from 'react';
import { getTransformer } from '../../conscia/conscia-transformers';
import {
    Alert,
    AlertTitle
} from '@salesforce/retail-react-app/app/components/shared/ui';

const AnnouncementBanner = ({title, message, ...props}) => {
    return (
        <Alert 
            status='info'
            variant='solid'
            textAlign='center'
            justifyContent='center'
        >
            <AlertTitle>{title}</AlertTitle>
            {message? getTransformer('stripHtml')(message) : ''}
        </Alert>
    )
}

export default AnnouncementBanner;